import React from 'react';
import styled from 'styled-components/macro';

const MainDiv = styled.div`
  max-width: 1112px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  background-size: contain;
  @media (max-width: ${props =>
      props.maxWidthMediaQuery ? props.maxWidthMediaQuery : '760px'}) {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
`;

function ActivityCard({
  customStyle,
  children,
  slider = false,
  maxWidthMediaQuery
}) {
  return (
    <MainDiv
      style={customStyle}
      slider={slider}
      maxWidthMediaQuery={maxWidthMediaQuery}
    >
      {children}
    </MainDiv>
  );
}

export default ActivityCard;
