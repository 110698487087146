import React from 'react';
import styled from 'styled-components/macro';

import { Paper } from '@material-ui/core';
import fontSize from '../../../assets/fontSize';

const ImageWrapper = styled.div`
  width: 272px;
  height: 177px;
`;
const Name = styled.h4`
  font-weight: 700;
  font-size: ${fontSize.large};
  line-height: 19px;
  margin-bottom: 0;
`;
const Description = styled.p`
  font-weight: 600;
  font-style: italic;
  font-size: 13px;
  line-height: 17.7px;
  height: 225px;
`;
const Body = styled.div`
  text-align: center;
  padding-left: 19px;
  padding-right: 19px;
  padding-bottom: 32px;
  height: 295px;
`;
const JobTitle = styled.p`
  font-size: ${fontSize.large};
  line-height: 19px;
  margin-top: 4px;
`;
const StyledPaper = styled(Paper)`
  && {
    box-shadow: 0px 4px 6px rgba(206, 206, 206, 0.5);
    border-radius: 20px;
    width: 272px;
    height: 517px;
    @media (max-width: 760px) {
      margin-right: 20px !important;
      margin-left: 20px !important;
    }
  }
`;
const TitleNameWrapper = styled.div``;

const OurStory = props => {
  const { image, description, name, jobTitle } = props;
  return (
    <StyledPaper>
      <ImageWrapper>{image}</ImageWrapper>
      <Body>
        <Description>{description}</Description>
        <TitleNameWrapper>
          <Name>{name}</Name>
          <JobTitle>{jobTitle}</JobTitle>
        </TitleNameWrapper>
      </Body>
    </StyledPaper>
  );
};

export default OurStory;
