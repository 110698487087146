import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Desktop1 = './assets/whatsHappening/Desktop1.jpg';
const Desktop2 = './assets/whatsHappening/Desktop2.jpg';
const Desktop3 = './assets/whatsHappening/Desktop3.jpg';

const Mobile1 = './assets/whatsHappening/Mobile1.jpg';
const Mobile2 = './assets/whatsHappening/Mobile2.jpg';
const Mobile3 = './assets/whatsHappening/Mobile3.jpg';
const Mobile4 = './assets/whatsHappening/Mobile4.jpg';
const Mobile5 = './assets/whatsHappening/Mobile5.jpg';
const Mobile6 = './assets/whatsHappening/Mobile6.jpg';

const whatsHappeningDataDesktop = [
  {
    id: 1,
    image: (
      <StaticImage
        src={Desktop1}
        placeholder="blurred"
        alt="GoGet Happenings"
      />
    )
  },
  {
    id: 2,
    image: (
      <StaticImage
        src={Desktop2}
        placeholder="blurred"
        alt="GoGet Happenings"
      />
    )
  },
  {
    id: 3,
    image: (
      <StaticImage
        src={Desktop3}
        placeholder="blurred"
        alt="GoGet Happenings"
      />
    )
  }
];

const whatsHappeningDataMobile = [
  {
    id: 1,
    image: (
      <StaticImage
        src={Mobile1}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="GoGet Happenings"
      />
    )
  },
  {
    id: 2,
    image: (
      <StaticImage
        src={Mobile2}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="GoGet Happenings"
      />
    )
  },
  {
    id: 3,
    image: (
      <StaticImage
        src={Mobile3}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="GoGet Happenings"
      />
    )
  },
  {
    id: 4,
    image: (
      <StaticImage
        src={Mobile4}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="GoGet Happenings"
      />
    )
  },
  {
    id: 5,
    image: (
      <StaticImage
        src={Mobile5}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="GoGet Happenings"
      />
    )
  },
  {
    id: 6,
    image: (
      <StaticImage
        src={Mobile6}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="GoGet Happenings"
      />
    )
  }
];

export { whatsHappeningDataDesktop, whatsHappeningDataMobile };
