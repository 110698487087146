import React from 'react';
import styled from 'styled-components/macro';
import { StaticImage } from 'gatsby-plugin-image';

import { dataRight, dataLeft } from './const/gogetBeliefsConst';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const crestLogo = './const/assets/beliefs/Crest.png';

const MainDiv = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 948px;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 71px;
  @media (max-width: 1155px) {
    width: unset;
  }
  @media (max-width: 967px) {
    justify-content: space-around;
    width: unset;
    margin: 0 22px;
  }
`;

const CrestImage = styled.div`
  width: 212px;
  height: 206px;
  margin-right: 7rem;
  @media (max-width: 967px) {
    margin-bottom: 55px;
    margin-right: 0;
  }
`;
const Icon = styled.div`
  width: 48px;
  height: 48px;
`;
const Text = styled.p`
  font-style: italic;
  font-weight: 600;
  margin: 0;
  font-size: ${fontSize.xLarge};
  color: ${colors.white};
  margin-left: 10px;
`;
const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  @media (max-width: 967px) {
    margin-bottom: 30px;
  }
`;
const BodyWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 967px) {
    flex-direction: column;
  }
`;
const BodyWrapperInner = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 967px) {
    flex-direction: column;
  }
`;
const RightItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 290px;
  @media (max-width: 967px) {
    width: unset;
  }
`;
const LeftItemsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 70px;
  width: 290px;
  @media (max-width: 967px) {
    margin-right: 0;
    width: unset;
  }
`;

const GoGetBeliefs = () => {
  return (
    <MainDiv>
      <BodyWrapper>
        <CrestImage>
          <StaticImage
            src={crestLogo}
            placeholder="blurred"
            alt="GoGet Crest"
          />
        </CrestImage>
        <BodyWrapperInner>
          <LeftItemsWrapper>
            {dataLeft.map(item => (
              <Item key={item.id} icon={item.icon} text={item.text} />
            ))}
          </LeftItemsWrapper>
          <RightItemsWrapper>
            {dataRight.map(item => (
              <Item key={item.id} icon={item.icon} text={item.text} />
            ))}
          </RightItemsWrapper>
        </BodyWrapperInner>
      </BodyWrapper>
    </MainDiv>
  );
};

const Item = props => {
  const { icon, text } = props;
  return (
    <ItemWrapper>
      <Icon>{icon}</Icon>
      <Text>{text}</Text>
    </ItemWrapper>
  );
};

export default GoGetBeliefs;
