import React from 'react';
import ActivityCard from '../ActivityCard';
import { Column, Text, SubText, ImageContainer } from './styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// TODO(Matt): Remove AdvantagesOfGoget V1 when all sections are optimised
function AdvantagesOfGoget({ data, textColor, hideBoxShadow }) {
  return (
    <div style={{ padding: '10px' }}>
      <ActivityCard
        hideBoxShadow
        customStyle={{
          marginTop: '-30px',
          padding: '28px',
          boxShadow: hideBoxShadow && 'none',
          alignItems: 'flex-start'
        }}
      >
        {data.map((obj, index) => {
          return (
            <Column key={index}>
              <ImageContainer>
                {obj?.img ||
                  (obj?.image && (
                    <GatsbyImage
                      image={getImage(obj.image)}
                      loading="eager"
                      alt={obj.image.alt}
                    />
                  ))}
              </ImageContainer>
              <Text color={textColor}> {obj?.uspTitle || obj?.header} </Text>
              <SubText>
                {obj?.uspDescription || obj?.desc || obj?.description}
              </SubText>
            </Column>
          );
        })}
      </ActivityCard>
    </div>
  );
}

export default AdvantagesOfGoget;
