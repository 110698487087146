import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Laugh = './assets/Laugh.png';
const Flat = './assets/Flat.png';
const GoGetFlower = './assets/GoGetFlower.png';
const Wellness = './assets/WellnessDesktop.png';
const Social = './assets/SocialDesktop.png';
const Growth = './assets/GrowthDesktop.png';

const data = [
  {
    id: 1,
    icon: <StaticImage src={Laugh} placeholder="blurred" alt="Laughing" />,
    image: <StaticImage src={Social} placeholder="blurred" alt="Social Team" />,
    title: 'Social team',
    subTitle: 'To build informal connection',
    items: ['Sport days', 'Social makans', 'Lepak/Fun times']
  },
  {
    id: 2,
    icon: <StaticImage src={Flat} placeholder="blurred" alt="Flat" />,
    image: (
      <StaticImage src={Wellness} placeholder="blurred" alt="Wellness Team" />
    ),
    title: 'Wellness team',
    subTitle: 'To promote physical and mental health',
    items: [
      'Mindful workshops',
      'Yoga & meditation',
      'Emotional intelligence',
      'Healthy habits',
      'Stress management'
    ]
  },
  {
    id: 3,
    icon: <StaticImage src={GoGetFlower} placeholder="blurred" alt="Flower" />,
    image: <StaticImage src={Growth} placeholder="blurred" alt="Growth Team" />,
    title: 'Growth team',
    subTitle:
      'To promote personal and professional growth as a team and individually',
    items: [
      'Trainings',
      'Peer-to-Peer learning',
      'Speaker events',
      'Lunch and learns',
      'Games'
    ]
  }
];

export { data };
