import styled from 'styled-components/macro';
import colors from '../../../assets/colors';
import fontSize from '../../../assets/fontSize';

export const ImageContainer = styled.div`
  height: 66px;
  width: 66px;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 80%;
  margin-bottom: 15px;
  min-width: 200px;
`;
export const Text = styled.div`
  font-size: ${fontSize.xLarge};
  color: ${({ color }) => color || colors.primary};
  font-weight: bold;
  text-align: center;
  margin-top: -5px;
  box-sizing: border-box;
  flex-basis: 30%;
  display: inline-table;
`;
export const SubText = styled.div`
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 300;
  box-sizing: border-box;
  flex-basis: 30%;
  width: 208px;
  @media (max-width: 480px) {
    width: 162px;
  }
`;
