import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Adaptive = './assets/Adaptive.jpg';
const GoGetter = './assets/GoGetter.jpg';
const Helpful = './assets/Helpful.jpg';
const Honest = './assets/Honest.jpg';
const Innovative = './assets/Innovative.jpg';
const Healthy = './assets/Healthy.jpg';

const ourCultureDataDesktop = [
  {
    id: 1,
    image: (
      <StaticImage
        src={Helpful}
        placeholder="blurred"
        style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
        alt="Helpful"
      />
    ),
    title: 'Helpful',
    description:
      'By being a supportive, empathetic, empowering and useful member of the community'
  },
  {
    id: 2,
    image: (
      <StaticImage
        src={Honest}
        placeholder="blurred"
        style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
        alt="Honest"
      />
    ),
    title: 'Honest',
    description:
      'By being transparent, direct, and maintaining integrity in our form of communication'
  },
  {
    id: 3,
    image: (
      <StaticImage
        src={GoGetter}
        placeholder="blurred"
        style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
        alt="GoGetter"
      />
    ),
    title: 'Go-Getter',
    description:
      'By taking the initiative, going the extra mile, and also an action taker'
  },
  {
    id: 4,
    image: (
      <StaticImage
        src={Healthy}
        placeholder="blurred"
        style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
        alt="Healthy"
      />
    ),
    title: 'Healthy',
    description:
      'By maintaining a healthy lifestyle of emotional, mental and physical aspects'
  },
  {
    id: 5,
    image: (
      <StaticImage
        src={Adaptive}
        placeholder="blurred"
        style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
        alt="Adaptive"
      />
    ),
    title: 'Adaptive',
    description:
      'By being flexible, dynamic, outcome driven and open minded to any challenges that come'
  },
  {
    id: 6,
    image: (
      <StaticImage
        src={Innovative}
        placeholder="blurred"
        style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
        alt="Innovative"
      />
    ),
    title: 'Innovative',
    description:
      'By being a game changer in considering creative and innovative ways in providing solutions'
  }
];

export { ourCultureDataDesktop };
