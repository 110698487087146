import React from 'react';
import styled, { css } from 'styled-components/macro';
import fontSize from '../assets/fontSize';

const Text = styled.h2`
  text-align: center;
  font-weight: bold;
  line-height: 49px;
  font-size: ${fontSize.twoRem};
  margin-top: ${props =>
    props.noMargin ? '0px' : props.marginExtra ? '11rem' : '6rem'};
  ${props =>
    props.styledDesktop &&
    css`
      ${props.styledDesktop}
    `};
  @media (max-width: 480px) {
    ${props =>
      props.styledMobile &&
      css`
        ${props.styledMobile}
      `};
  }
`;

function TitleHeader({
  text,
  marginExtra,
  noMargin,
  styledMobile,
  styledDesktop
}) {
  return (
    <Text
      marginExtra={marginExtra}
      noMargin={noMargin}
      styledMobile={styledMobile}
      styledDesktop={styledDesktop}
    >
      {text}
    </Text>
  );
}

export default TitleHeader;
