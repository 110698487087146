import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components/macro';
import TitleHeaderText from '../../components/TitleHeaderText';
import { Paper, Button } from '@material-ui/core';
import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const MainDiv = styled.div`
  justify-content: center;
  flex-wrap: wrap;
  width: 948px;
  margin: auto;
  @media (max-width: 1155px) {
    width: unset;
    margin: 0 2rem;
  }
  @media (max-width: 760px) {
    width: unset;
    margin: 0 22px;
  }
`;
const StyledPaper = styled(Paper)`
  && {
    padding: 48px 28px;
    box-shadow: 0px 4px 6px rgba(206, 206, 206, 0.5);
    border-radius: 20px;
    margin: auto;
  }
`;
const StyledButton = styled(Button)`
  && {
    border: 1px solid ${colors.borderGrey};
    padding: 25px 34px;
    display: flex;
    align-item: center;
    border-radius: 12px;
    background: ${colors.white};
    text-transform: capitalize;
    width: 100%;
    margin-bottom: 28px;
    @media (max-width: 760px) {
      padding: 1rem;
      height: unset;
      > span.MuiButton-label {
        flex-direction: column;
        align-items: baseline;
      }
    }
  }
`;
const JobTitle = styled.div`
  color: ${colors.primary};
  font-size: ${fontSize.xLarge};
  font-weight: 700;
  line-height: 21.79px;
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 5rem;
  @media (max-width: 760px) {
    margin-bottom: 14px;
    text-align: initial;
    line-height: 23px;
    margin-right: unset;
  }
`;
const Text = styled.div`
  color: ${colors.black};
  font-size: ${fontSize.xLarge};
  font-weight: 400;
  line-height: 21.79px;
  flex: ${props => (props.isLocation ? 'unset' : 1)};
  @media (max-width: 760px) {
    margin-bottom: ${props => (props.isLocation ? '0' : '12px')};
  }
`;

const ExploreJob = () => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    sizeHandler();
    window.addEventListener('resize', sizeHandler);
  }, []);

  const sizeHandler = () => {
    if (window.innerWidth <= 760) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const allJDs = useStaticQuery(graphql`
    query AllCareers {
      allDatoCmsCareer {
        edges {
          node {
            originalId
            jobTitle
            slug
            department
            location
          }
        }
      }
    }
  `);

  const allJDsIncludeDatoCMS = allJDs?.allDatoCmsCareer?.edges;

  const renderTitle = () => {
    if (isMobile) {
      return (
        <>
          Explore job <br /> openings
        </>
      );
    }
    return 'Explore job openings';
  };
  return (
    <MainDiv>
      <TitleHeaderText
        styledDesktop={{ marginTop: '4rem !important' }}
        text={renderTitle()}
      />
      <StyledPaper>
        {allJDsIncludeDatoCMS.map(item => (
          <CardItem
            key={item.id || item?.node?.originalId}
            jobTitle={item.jobTitle || item?.node?.jobTitle}
            department={item.department || item?.node?.department}
            location={item.location || item?.node?.location}
            url={item.url || item?.node?.slug}
          />
        ))}
      </StyledPaper>
    </MainDiv>
  );
};

const CardItem = props => {
  const { jobTitle, department, location, url } = props;
  return (
    <StyledButton component={Link} to={`/${url}`}>
      <JobTitle>{jobTitle}</JobTitle>
      <Text>{department}</Text>
      <Text isLocation>{location}</Text>
    </StyledButton>
  );
};

export default ExploreJob;
