import React from 'react';
import styled from 'styled-components/macro';

import ActivityCard from '../ActivityCard';

const ImageWrapper = styled.div`
  width: 250px;
`;
const Title = styled.h4`
  font-weight: 700;
  margin-top: 21.8px;
  margin-bottom: 12px;
`;
const Description = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 17.7px;
`;
const Body = styled.div`
  padding-left: 19px;
  padding-right: 19px;
  padding-bottom: 32px;
`;

const BasicCard = props => {
  const { image, description, title } = props;
  return (
    <ActivityCard
      maxWidthMediaQuery="824px"
      customStyle={{
        boxShadow: '0px 4px 6px rgba(206, 206, 206, 0.5)',
        borderRadius: 20,
        height: 274,
        width: 250
      }}
    >
      <ImageWrapper>{image}</ImageWrapper>
      <Body>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Body>
    </ActivityCard>
  );
};

export default BasicCard;
