import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';

import GoGetBeliefBackgroundDesktop from '../assets/img/careers/GoGetBeliefBackgroundDesktop.png';
import GoGetBeliefBackgroundMobile from '../assets/img/careers/GoGetBeliefBackgroundMobile.png';
import OurculturesBackgroundMobile from '../assets/img/careers/OurculturesBackgroundMobile.png';
import OurculturevaluesBackgroundDesktop from '../assets/img/careers/OurculturevaluesBackgroundDesktop.png';
import WhathappeingBackgroundDesktop from '../assets/img/careers/WhathappeingBackgroundDesktop.png';
import WhatshappeningBackgroundMobile from '../assets/img/careers/WhatshappeningBackgroundMobile.png';

import TopHeader from '../views/shared/TopHeaderV2';
import AdvantagesOfGoget from '../views/shared/AdvantagesOfGogetV2';

import OurCultureValues from '../views/CareersPage/OurCultureValues';
import BePartOfOurTeam from '../views/CareersPage/BePartOfOurTeam';
import OurStory from '../views/CareersPage/OurStory';
import WhatsHappening from '../views/CareersPage/WhatsHappening';
import ExploreJob from '../views/CareersPage/ExploreJob';
import GoGetBeliefs from '../views/CareersPage/GoGetBeliefs';

import HeaderSeo from '../components/HeaderSeo';

import MetaImg from '../assets/img/meta/homelife.png';

import { SharedBackground } from '../assets/sharedStyle';

const BannerDesktop = '../assets/img/careers/MainBannerDesktop.jpg';
const BannerMobile = '../assets/img/careers/MainBannerMobile.jpg';

const Everyone = '../assets/img/careers/advantageOfGoGet/Everyone.svg';
const Good = '../assets/img/careers/advantageOfGoGet/Good.svg';
const Grounded = '../assets/img/careers/advantageOfGoGet/Grounded.svg';
const Optimise = '../assets/img/careers/advantageOfGoGet/Optimise.svg';
const Trusted = '../assets/img/careers/advantageOfGoGet/Trusted.svg';

const FooterBackground = styled.div`
  margin-top: 5rem;
  margin-bottom: 2rem;
  background-image: url(${GoGetBeliefBackgroundDesktop});
  ${SharedBackground};
  @media (max-width: 760px) {
    background-image: url(${GoGetBeliefBackgroundMobile});
  }
`;
const OurCultureWrapper = styled.div`
  margin-top: 3rem;
  background-image: url(${OurculturevaluesBackgroundDesktop});
  ${SharedBackground};
  @media (max-width: 760px) {
    background-image: url(${OurculturesBackgroundMobile});
  }
`;
const WhatsHappeningWrapper = styled.div`
  margin-top: 3rem;
  background-image: url(${WhathappeingBackgroundDesktop});
  ${SharedBackground};
  @media (max-width: 760px) {
    background-image: url(${WhatshappeningBackgroundMobile});
  }
`;

const behaviour = {
  behavior: 'smooth',
  block: 'start'
};

const Careers = props => {
  const refExploreJob = useRef(null);
  const [calcScrollToView, setCalcScrollToView] = useState();
  useEffect(() => {
    const hash = window.location.hash.substr(1);

    if (hash === 'exploreJobs') {
      refExploreJob.current.scrollIntoView(behaviour);
      setCalcScrollToView(0);
    } else {
      window.scrollTo(0, 0);
    }
  }, [calcScrollToView]);

  const handleClick = () => {
    if (refExploreJob.current) {
      refExploreJob.current.scrollIntoView(behaviour);
    }
  };

  const advantagesOfGoget = [
    {
      img: (
        <StaticImage
          src={Good}
          placeholder="blurred"
          loading="eager"
          alt="Good"
        />
      ),
      header: 'Good',
      desc: (
        <>
          We are committed to do <br />
          <b>GOOD</b> by creating an impact through shared resources
        </>
      )
    },
    {
      img: (
        <StaticImage
          src={Optimise}
          placeholder="blurred"
          loading="eager"
          alt="Optimise"
        />
      ),
      header: 'Optimise',
      desc: (
        <>
          We aim to <b>OPTIMISE</b> on
          <br /> feedback by listening and creating the best platform for our
          users
        </>
      )
    },
    {
      img: (
        <StaticImage
          src={Grounded}
          placeholder="blurred"
          loading="eager"
          alt="Grounded"
        />
      ),
      header: 'Grounded',
      desc: (
        <>
          We are <b>GROUNDED</b> in the
          <br /> community as we believe in the power of the community
        </>
      )
    },
    {
      img: (
        <StaticImage
          src={Everyone}
          placeholder="blurred"
          loading="eager"
          alt="Everyone"
        />
      ),
      header: 'Everyone',
      desc: (
        <>
          We want to create an inclusive
          <br /> platform for <b>EVERYONE</b>,<br /> regardless of background,
          as
          <br /> we believe everyone should <br />
          have the chance to get work
        </>
      )
    },
    {
      img: (
        <StaticImage
          src={Trusted}
          placeholder="blurred"
          loading="eager"
          alt="Trusted"
        />
      ),
      header: 'Trusted',
      desc: (
        <>
          We strive to be a <b>TRUSTED</b> and reliable partner in solving your
          problem
        </>
      )
    }
  ];
  const careersConstant = {
    title: (
      <>
        Building the <br /> future of work
      </>
    ),
    buttonText: 'Join us'
  };
  return (
    <>
      <HeaderSeo
        title="GoGet | Careers - Build the future of work together with us!"
        description="Join our diverse team as we strive to do Good, Optimise, stay Grounded, create an inclusive platform for Everyone and be Trusted to solve all problems."
        image={MetaImg}
        keywords=""
        pathname={props.location.pathname}
      />
      <Layout
        isShowingFooterAppBar
        footerAppData={{
          title: 'Let’s work together',
          subtitle: ' ', // leacve the space to hide subtitle value
          textButon: 'EXPLORE',
          link: '',
          isLinkHidden: true,
          onClick: () => {
            handleClick();
            // TODO: update this when needed
            // window.analytics.track('expl', {
            //   category: 'clickthrough',
            //   label: 'anchor_banner',
            //   platform: 'static',
            //   destination: '/gogetters'
            // })
          }
        }}
      >
        <TopHeader
          backgroundImg={
            <StaticImage
              src={BannerDesktop}
              loading="eager"
              style={{ height: '100%' }}
              quality={100}
              objectPosition="left top"
              placeholder="blurred"
              alt="GoGet Community"
            />
          }
          mobileBackgroundImg={
            <StaticImage
              src={BannerMobile}
              loading="eager"
              style={{ width: '100%' }}
              objectFit="contain"
              placeholder="blurred"
              alt="GoGet Community"
            />
          }
          title={careersConstant.title}
          btn1={{ text: careersConstant.buttonText, onClick: handleClick }}
          h4="Careers"
          backgroundMobileHeight="880px"
          secondary
        />
        <AdvantagesOfGoget data={advantagesOfGoget} />
        <OurCultureWrapper>
          <OurCultureValues />
        </OurCultureWrapper>
        <BePartOfOurTeam />
        <OurStory />
        <WhatsHappeningWrapper>
          <WhatsHappening />
        </WhatsHappeningWrapper>
        <span
          ref={refExploreJob}
          style={{ scrollMargin: calcScrollToView ? calcScrollToView : 100 }}
        >
          <ExploreJob />
        </span>
        <FooterBackground>
          <GoGetBeliefs />
        </FooterBackground>
      </Layout>
    </>
  );
};

export default Careers;
