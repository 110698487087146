import React from 'react';
import styled from 'styled-components/macro';
import TitleHeaderText from '../../components/TitleHeaderText';
import Slider from '../../views/LandingPage/Slider';
import { data } from './const/ourStoryConst';
import OurStoryCard from '../shared/OurStoryCard';
import colors from '../../assets/colors';

const MainDiv = styled.div`
  justify-content: center;
  flex-wrap: wrap;
  width: 1050px;
  margin: auto;
  @media (max-width: 1155px) {
    width: unset;
    margin: unset;
  }
  .flickity-prev-next-button.previous {
    left: -46px;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1155px) {
      display: none;
    }
  }
  .flickity-prev-next-button.next {
    right: -46px;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1155px) {
      display: none;
    }
  }
  .flickity-viewport {
    padding-bottom: 1rem !important;
  }
  .flickity-page-dots .dot {
    background: ${colors.primary};
  }
`;
const FlexWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: 0 auto;
  height: 600px;
  @media only screen and (max-width: 1155px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media only screen and (max-width: 865px) {
    display: none;
    margin-left: unset;
    margin-right: unset;
  }
`;
const MobileWrapper = styled.div`
  display: none;
  @media only screen and (max-width: 865px) {
    display: block;
  }
`;

const OurStory = () => {
  const options = {
    wrapAround: true,
    freeScroll: false,
    fullscreen: true,
    adaptiveHeight: true,
    draggable: true,
    pageDots: true,
    contain: true,
    prevNextButtons: false
  };

  const renderCard = () => {
    return (
      data &&
      data.map(data => {
        return (
          <OurStoryCard
            key={data.id}
            image={data.image}
            description={data.description}
            name={data.name}
            jobTitle={data.jobTitle}
          />
        );
      })
    );
  };

  return (
    <MainDiv>
      <TitleHeaderText text="Our story" />
      <FlexWrapper>{renderCard()}</FlexWrapper>
      <MobileWrapper>
        <Slider options={options}>{renderCard()}</Slider>
      </MobileWrapper>
    </MainDiv>
  );
};

export default OurStory;
