import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import TitleHeaderText from '../../components/TitleHeaderText';
import { data } from './const/bePartofTeamConst';
import BePartofTeamCard from '../shared/BePartofTeamCard';

const MainDiv = styled.div`
  justify-content: center;
  flex-wrap: wrap;
  width: 1050px;
  margin: auto;
  @media (max-width: 1155px) {
    width: unset;
    margin: unset;
  }
`;

const BePartOfOurTeam = () => {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    window.addEventListener('resize', sizeHandler);
    return sizeHandler();
  }, [mobile]);

  const sizeHandler = () => {
    if (window.innerWidth <= 935) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  return (
    <MainDiv>
      <TitleHeaderText text="Be part of our teams" />
      {data.map(item => (
        <BePartofTeamCard
          key={item.id}
          icon={item.icon}
          items={item.items}
          title={item.title}
          subTitle={item.subTitle}
          image={item.image}
          isImageRight={item.id === 2 && !mobile}
          isFlex={item.id !== 1}
        />
      ))}
    </MainDiv>
  );
};

export default BePartOfOurTeam;
