import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Expect = './assets/beliefs/Expect.png';
const Gym = './assets/beliefs/Gym.png';
const Hands = './assets/beliefs/Hands.png';
const Rule = './assets/beliefs/Rule.png';
const Runner = './assets/beliefs/Runner.png';
const Wings = './assets/beliefs/Wings.png';

const dataLeft = [
  {
    id: 1,
    icon: <StaticImage src={Rule} placeholder="blurred" alt="Rules" />,
    text: 'Be the one to change the rules'
  },
  {
    id: 2,
    icon: <StaticImage src={Expect} placeholder="blurred" alt="Expect" />,
    text: 'Expect the unexpected'
  },
  {
    id: 3,
    icon: <StaticImage src={Gym} placeholder="blurred" alt="Gym" />,
    text: (
      <>
        Never forsake mind body <br /> and soul
      </>
    )
  }
];
const dataRight = [
  {
    id: 4,
    icon: <StaticImage src={Runner} placeholder="blurred" alt="Runner" />,
    text: 'Be the extra in extraordinary'
  },
  {
    id: 5,
    icon: <StaticImage src={Hands} placeholder="blurred" alt="Hands" />,
    text: (
      <>
        Where help is needed, <br /> help is given
      </>
    )
  },
  {
    id: 6,
    icon: <StaticImage src={Wings} placeholder="blurred" alt="Wings" />,
    text: (
      <>
        Be true to yourself and <br /> those around you
      </>
    )
  }
];

export { dataLeft, dataRight };
