import Link from 'gatsby-link';
import styled from 'styled-components/macro';

import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import Button from '@material-ui/core/Button';

import colors from '../../../assets/colors';
import fontSize from '../../../assets/fontSize';

export const H4 = styled.h4`
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${props => (!!props.color ? props.color : colors.white)};
  position: relative;
`;

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-top: 14rem;
  padding-bottom: 3rem;
  overflow: hidden;
  background-color: ${({ backgroundImage }) =>
    backgroundImage ? 'transparent' : colors.primary};
  @media (max-width: 630px) {
    padding-top: ${props =>
      props.mobilePaddingTop ? props.mobilePaddingTop : '10rem'};
    margin-top: 67px;
    height: ${props =>
      props.backgroundMobileHeight ? props.backgroundMobileHeight : '700px'};
  }
`;
export const DesktopImageContainer = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;

  @media (max-width: 630px) {
    display: none;
  }
`;
export const MobileImageContainer = styled.div`
  z-index: -1;
  display: none;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 630px) {
    display: block;
    height: 100%;
  }
`;
export const MainWrap = styled.div`
  display: flex;
  width: calc(100% - 2rem);
  max-width: 1200px;
  margin: auto;
  color: orange;
  @media (max-width: 580px) {
    max-width: none;
    margin-top: -45px;
  }
`;
export const LeftWrap = styled.div`
  // width: 40%;
  padding-bottom: 5rem;
  min-width: 559.5px;
  @media (max-width: 580px) {
    width: 100%;
    min-width: unset;
    padding-bottom: 2rem;
    // padding-left: 1rem;
  }
`;
export const RightWrap = styled.div`
  width: 40%;
  display: flex;
  align-items: flex-start;
  padding: 3rem;
  display: none;
  @media (min-width: 580px) {
    display: flex;
    justify-content: center;
    padding-top: 0;
    padding-left: 0;
  }
`;
export const StyledPlayIcon = styled(PlayIcon)`
  font-size: 5rem !important;
  color: ${colors.white};

  @media (max-width: 580px) {
    font-size: 3.5rem !important;
    padding-top: 1rem;
    display: block;
    ${props => props.playiconmargintop};
  }
`;
export const FlexDiv = styled.div`
  display: flex;
  @media (max-width: 580px) {
    flex-direction: ${props => props.main === 'true' && 'column'};
  }
`;
export const Title = styled.h1`
  color: ${props => (props.color ? `${props.color}` : `${colors.white}`)};
  font-size: ${fontSize.threeHalfRem};
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 0;
  @media (max-width: 580px) {
    font-size: ${fontSize.twoRem};
    margin-right: 1rem;
    width: unset;
  }
`;
export const Description = styled.h2`
  margin-top: 0;
  width: ${({ backgroundImg, mobileBackgroundImg }) =>
    backgroundImg || mobileBackgroundImg ? '500px' : 'unset'};
  color: ${props => (props.color ? `${props.color}` : `${colors.white}`)};
  font-size: ${fontSize.xxLarge};
  font-weight: 300;
  line-height: 1.5rem;
  margin-bottom: 2rem;
  white-space: pre-wrap;
  margin-left: ${props => props.extraMargin && '5px'};
  @media (max-width: 580px) {
    font-size: ${fontSize.large};
    width: calc(100% - 3rem);
  }
`;
export const StyledButton = styled(Button)`
  background-color: ${colors.primary} !important;
  color: ${colors.white} !important;
  margin-right: ${props => props.marginright === 'true' && '1rem !important'};
  font-family: 'Open Sans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 0.5rem !important;
  height: 59px;
  width: 230px !important;
  border-radius: 8px !important;
  @media (max-width: 580px) {
    font-size: 14px !important;
    // width: 50% !important;
  }
  &.button {
    &.secondary {
      background-color: ${colors.white} !important;
      a {
        color: ${colors.primary} !important;
      }

      &.hover {
        background-color: ${colors.white} !important;
        a {
          color: ${colors.primary} !important;
        }
      }
    }
  }
  @media (max-width: 550px) {
    margin-bottom: ${props => props.main === 'true' && '1rem !important'};
  }
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.white};
  &.button {
    &.secondary {
      button {
        color: ${colors.primary} !important;
      }
    }
  }
`;
export const MobileDiv = styled.div`
  display: none;
  @media (max-width: 580px) {
    display: contents;
  }
`;
export const StyledImage = styled.img`
  width: 178px;
  margin-top: 15px;
  margin-right: 10px;
  @media (max-width: 411px) {
    width: 150px;
  }
  @media (max-width: 355px) {
    width: 136px;
  }
`;
export const AppStoreDiv = styled.div`
  margin-top: -28px;
  margin-left: 4px;
`;
