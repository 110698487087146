import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Faisal = './assets/Faisal.jpg';
const Madeline = './assets/Madeline.jpg';
const Roshan = './assets/Roshan.jpg';

const data = [
  {
    id: 1,
    image: (
      <StaticImage
        src={Roshan}
        placeholder="blurred"
        style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
        alt="Roshan"
      />
    ),
    name: 'Roshan',
    jobTitle: 'Junior Associate',
    description: `We strive to provide job opportunities for
      everyone regardless of background, and that is something I
      feel very proud of when I decided to work at GoGet. We are
      also passionate about creating impactful changes that provide
      sustainable solutions to our community. This makes what I do here
      more meaningful!`
  },
  {
    id: 2,
    image: (
      <StaticImage
        src={Madeline}
        placeholder="blurred"
        style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
        alt="Madeline"
      />
    ),
    name: 'Madeline',
    jobTitle: (
      <>
        Community Support <br />
        Manager
      </>
    ),
    description: `"G" in GoGet stands for to be committed to do good, to create an impact.
      Having been with the company for the last few years, I can really see that the value is
      lived and breathed across the team in everything we do, from rolling out new features to
      strengthening policies and also in resolving daily operational issues, no matter how big
      or small it is. It motivates me to be proud and give my best, knowing that we are impacting
      people's life every step of the way.`
  },
  {
    id: 3,
    image: (
      <StaticImage
        src={Faisal}
        placeholder="blurred"
        style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
        alt="Faisal"
      />
    ),
    name: 'Faisal',
    jobTitle: 'Head of Business Development',
    description: `I aim to create an impact in the communities that we
      serve and directly contributing to the growth of the on-demand workforce
      in Malaysia. That's why GoGet is the place to be!`
  }
];

export { data };
