import React from 'react';
import styled from 'styled-components/macro';
import { Paper } from '@material-ui/core';
import fontSize from '../../../assets/fontSize';
import colors from '../../../assets/colors';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 4rem;
  @media only screen and (max-width: 760px) {
    flex-direction: column;
    margin-bottom: 1rem;
  }
`;
const StyledPaper = styled(Paper)`
  && {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0px 4px 6px rgba(206, 206, 206, 0.5);
    border-radius: 20px;
    width: 488px;
    height: 273px;
    @media only screen and (max-width: 935px) {
      width: 264px;
      height: 100%;
    }
    @media only screen and (max-width: 345px) {
      width: 244px;
      height: 100%;
    }
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.p`
  font-size: 19.5px;
  font-weight: 800;
  margin-left: 8px;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 0;
`;
const SubTitle = styled.p`
  font-size: 19.2px;
  font-weight: 700;
  line-height: 32px;
`;
const Body = styled.div`
  font-size: ${fontSize.xLarge};
  font-weight: 400;
  line-height: 32px;
  display: ${props => props.isFlex && 'flex'};
  flex-wrap: wrap;
  @media only screen and (max-width: 935px) {
    display: unset;
    flex-wrap: unset;
  }
`;
const Bolet = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${colors.black};
  margin-right: 8px;
`;
const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 240px;
`;
const Icon = styled.div`
  width: 38px;
  height: 38px;
`;
const ImageWrapper = styled.div`
  width: 361px;
  height: 318px;
  @media only screen and (max-width: 935px) {
    width: 295px;
    height: 265px;
    margin-bottom: 1rem;
  }
`;

const BePartofTeamCard = props => {
  const { icon, items, title, subTitle, image, isImageRight, isFlex } = props;
  const renderCard = () => (
    <StyledPaper>
      <TitleWrapper>
        <Icon>{icon}</Icon>
        <Title>{title}</Title>
      </TitleWrapper>
      <SubTitle>{subTitle}</SubTitle>
      <Body isFlex={isFlex}>
        {items.map((item, index) => (
          <ItemWrapper key={index}>
            <Bolet />
            {item}
          </ItemWrapper>
        ))}
      </Body>
    </StyledPaper>
  );

  const renderImage = () => <ImageWrapper>{image}</ImageWrapper>;

  return (
    <Wrapper>
      {isImageRight ? (
        <>
          {renderCard()}
          {renderImage()}
        </>
      ) : (
        <>
          {renderImage()}
          {renderCard()}
        </>
      )}
    </Wrapper>
  );
};

export default BePartofTeamCard;
